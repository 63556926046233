export class ResponseError extends Error {
	public response: Response;

  constructor(res: Response, message?: string) {
		if (message) {
			super(message)
		} else {
			super()
		}
    this.response = res
  }
}
