import 'owl.carousel';
import type { ContentItem } from '../types/content'
import type { ContentApiService } from '../api/content';
import jQuery from 'jquery';
import { ResponseError } from '../api/ResponseError';
import { unwrapElement, wrapElement } from '../lib/dom'
import { BasicContentService } from '../api/content';

const template = document.createElement('template');
const style = `
<style>
:host {
  font-family: "Roboto", sans-serif;
  white-space: normal;
}
h3 img {
  height: auto;
  max-width: 8rem;
  width: 100%;
}
footer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  padding-block: 0.5rem;
  & a {
    color: black;
    text-decoration: none;
    & :is(:visited, :hover, :active) {
      color: inherit;
    }
  }
  & :is(a, button) {
    align-items: center;
    background: unset;
    border: unset;
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-right: 1rem;
    & :last-child {
      font-size: 1.25rem;
      line-height: 1;
      padding-bottom: 0.25rem;
    }
    & :active {}
  }
}
#carousel {
  container-type: inline-size;
  & a {
    text-decoration: none;
  }
}
.carousel-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 0 -0.75rem;
  @container (max-width: 760px) {
    font-size: 1.125rem;
  }
}
.tag-line {
  font-size: 0.75rem;
}
.owl-carousel .owl-nav .owl-next span,
.owl-carousel .owl-nav .owl-prev span {
  font-size: 2.125rem;
  font-weight: 500;
  margin-top: -0.325rem;
}
.owl-carousel .owl-nav .owl-next span {
  margin-left: 0.125rem;
}
.owl-carousel .owl-nav .owl-prev span {
  margin-left: -0.125rem;
}
.owl-carousel {
  display: none;
  padding-top: 2rem;
  position: relative;
  width:100%;
  z-index: 1
  -webkit-tap-highlight-color: transparent;
}
.owl-carousel .owl-stage {
  position: relative;
}
.owl-carousel .owl-stage-outer {
  overflow: hidden;
  position: relative;
}
.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  border: none;
  cursor: pointer;
  cursor: hand;
  font-size: 2.5rem;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  display: block;
  opacity: 0;
}
.owl-carousel .owl-item {
  float: left;
  min-height: 1px;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}
.owl-dots {
  bottom: 0.625rem;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
}
.owl-dots .owl-dot span {
  background: #fff;
  border-radius: 10px;
  display: inline-block;
  height: 0.625rem;
  margin: 0 0.125rem;
  opacity: 0.5;
  width: 0.625rem;
}
.owl-dot.active span {
  opacity: 1;
}
.owl-nav .owl-next,
.owl-nav .owl-prev {
  align-items: center;
  background: linear-gradient(0deg,#70efde 0,rgba(112,239,222,0) 100%),#49c8ef !important;
  border-radius: 50%;
  display: flex;
  height: 1.75rem;
  justify-content: center;
  position: absolute;
  right: 3.5rem;
  top: 0;
  transform: translateY(-50%);
  width: 1.75rem;
  z-index: 2;
}
.owl-nav .owl-next img,
.owl-nav .owl-prev img {
  height: 0.75rem;
}
.owl-nav .owl-next {
  left: inherit;
  right: 0.5rem;
}
.owl-nav .disabled {
  opacity: 0.75;
  background: #00406B !important;
  & > span {
    color: #49C8EF;
  }
}
</style>
`;
const component = `
<div id="carousel-wrapper">
  <h3 class="carousel-title">
    <img src="https://images.grafa.com/bolton/logo.svg" alt="View more on Grafa">
  </h3>
  <div id="carousel" class="owl-carousel owl-theme"></div>
  <footer>
    <a id="view-more" href="https://www.grafa.com/news">
      <span>View more</span>
      <span>›</span>
    </a>
  </footer>
</div>
`;
template.innerHTML = `
${style}
${component}
`;

export class CarouselComponent extends HTMLElement {
  carousel: HTMLElement | null | undefined;
  contentItems: ContentItem[] = [];
  viewMoreEl: HTMLElement | null | undefined;

  public contentService: ContentApiService;

  private _handleItemClick = (_: MouseEvent) => {};

  constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: 'open' });
    const clone = template.content.cloneNode(true);
    shadowRoot.appendChild(clone);

    this.contentService = new BasicContentService();
  }

  static get observedAttributes() {
    return ['data-feed-key', 'standalone', 'viewing', 'view-more'];
  }

  get feedKey() {
    return this.getAttribute('data-feed-key') ?? '';
  }

  // Viewing attribute to tell the carousel not to show this item as we are currently
  // viewing it on the page.
  get viewing() {
    const contentId = this.getAttribute('viewing');
    if (!contentId)
      return
    return Number.parseInt(contentId) || undefined;
  }

  get viewMore() {
    return this.getAttribute('view-more');
  }


  public disconnectCallback() {
    this._removeCarouselItemEventListener();
    this._removeViewMoreEventListeners();
  }

  async attributeChangedCallback(attrName: string, _: string | null, newValue: string) {
    this._attachOrRemovePopupWrapper(attrName, newValue);
    this._setViewMore(attrName, newValue);
    this._viewingChange(attrName);
    this._feedKeyChange(attrName, newValue);
  }

  /**
   * Attaches/removes a wrapping component that handles showing popups for selected items.
   *
   * @param attrName attribute name to check against.
   * @param value the value of the attribute.
   */
  private _attachOrRemovePopupWrapper(attrName: string, value: string) {
    if (attrName !== 'standalone')
      return
    const carouselWrapper = this.shadowRoot?.getElementById('carousel-wrapper');
    if (carouselWrapper) {
      const popupWrapper = document.createElement('popup-wrapper');
      if (value === 'true') {
        // Attach popup wrapper
          carouselWrapper.setAttribute('slot', 'wrapper-content');
          wrapElement(carouselWrapper, popupWrapper);
      } else {
        const popupWrapper = this.shadowRoot?.querySelector('popup-wrapper') as HTMLElement;
        if (!popupWrapper)
          return
        // Otherwise remove popup wrapper if it exists
        const parent = this.shadowRoot || undefined;
        unwrapElement(carouselWrapper, popupWrapper, parent);
      }
    }
  }

  /**
   * Rerenders the carousel on feed key change.
   *
   * @param attrName Name of the changed attribute.
   */
  private _feedKeyChange(attrName: string, value: string) {
    if (attrName !== 'data-feed-key' || !value)
      return
    this.renderCarousel(value);
  }

  /**
   * Rerenders the carousel on viewing content item change.
   *
   * @param attrName Name of the changed attribute.
   */
  private _viewingChange(attrName: string) {
    if (attrName !== 'viewing')
      return
    this.renderCarousel();
  }

  /**
   * Removes the popup event listener from grid-items if it exists. 
   */
  private _removeCarouselItemEventListener() {
    this.carousel = this.shadowRoot?.getElementById('carousel');
    this.carousel?.removeEventListener('click', this._handleItemClick);
  }
  
  /**
   * Removes the viewMore event listener from view more button if it exists. 
   */
  private _removeViewMoreEventListeners() {
    this.viewMoreEl = this.shadowRoot?.getElementById('view-more');
    this.viewMoreEl?.removeEventListener('click', this.dispatchViewMoreEvent);
  }

  /**
   * Show/hide the view more anchor/button and attaches any events.
   *
   * @param attrName attribute name to check against.
   * @param value the value of the attribute.
   */
  private _setViewMore(attrName: string, value: string | undefined) {
    if (attrName !== 'view-more')
      return
    this.viewMoreEl = this.shadowRoot?.getElementById('view-more');
    this._removeViewMoreEventListeners();

    // Remove the element if it exists and the viewMore attribute is false
    if (value === 'false') {
      this.viewMoreEl?.parentNode?.removeChild(this.viewMoreEl);
      return
    }

    // If 'true' then dispatch a view more event for external listening
    if (value === 'true') {
      const buttonInnerHTML = `
      <span>View more</span>
      <span>›</span>
      `
      const viewMoreEl = document.createElement('button');
      viewMoreEl.setAttribute('id', 'view-more');
      viewMoreEl.setAttribute('type', 'button');
      viewMoreEl.innerHTML = buttonInnerHTML

      if (this.viewMoreEl) {
        // If the element already exists, replace it with the new one
        this.viewMoreEl.replaceWith(viewMoreEl);
      } else {
        // Otherwise append the view more action to the footer
        const footer = this.shadowRoot?.querySelector('footer');
        footer?.appendChild(viewMoreEl);
      }
      viewMoreEl.addEventListener('click', this.dispatchViewMoreEvent);
    }

    // Default to view more anchor tag redirecting to grafa.com
    if (!value) {
      const viewMoreEl = document.createElement('a');
      viewMoreEl.setAttribute('id', 'view-more');
      viewMoreEl.setAttribute('href', 'https://www.grafa.com');
      if (this.viewMoreEl) {
        // Replace current element with view more anchor tag redirecting to grafa.com
        this.viewMoreEl.replaceWith(viewMoreEl);
      } else {
        // Otherwise append the view more action to the footer
        const footer = this.shadowRoot?.querySelector('footer');
        footer?.appendChild(viewMoreEl);
      }
      return
    }
  }

  /**
   * Dispatches a view more event for external listeners to action.
   * 
   * @param event
   */
  public dispatchViewMoreEvent(event: Event) {
    const element = event.target as HTMLElement;
    // Emit viewMore event
    element?.dispatchEvent(new CustomEvent("viewMore", {
      bubbles: true,
      cancelable: false,
      composed: true,
      detail: {
        component: 'carousel-component',
      },
    }));
  }

  /**
   * Fetches the content items to display in the grid/list.
   *
   * @param publishDate 
   * @returns an array of the latest content items.
   */
  private async fetchData(feedKey: string) {
    const feedParams = { feedKey }
    return this.contentService.getCustomContentFeed(feedParams)
  }

  /**
   * Renders the carousel with the fetched items.
   */
  private async renderCarousel(feedKey?: string) {
    this.carousel = this.shadowRoot?.querySelector('#carousel');
    if (!this.carousel)
      return

    try {
      let contentItems = this.contentItems
      if (feedKey)
        contentItems = await this.fetchData(feedKey);
      if (contentItems?.length) {
        this.contentItems = contentItems.filter((item: ContentItem) => item.ContentId !== this.viewing);
        const displayItems = this.contentItems?.map((item: ContentItem) => {
          return `
            <a href="javascript:void(0)">
              <carousel-item
                data-id="${item.ContentId}"
                data-title="${item.Title}"
                data-thumbnail="${item.ThumbnailURL}"
                data-source="${item.Source}"
                data-publish="${item.PublishDate}">
              </carousel-item>
            </a>
          `;
        });
        const renderItems = `${displayItems?.join("")}`;
        this.carousel.innerHTML = renderItems;
    
        this.updateCarouselConfig();
    
        this._removeCarouselItemEventListener();
        this._handleItemClick = (event: MouseEvent) => {
          const anchor = (event.target as HTMLElement)?.closest('a')
          const contentId = (anchor?.querySelector('carousel-item') as HTMLElement)?.dataset.id;
          // Emit popup event with contentId
          anchor?.dispatchEvent(new CustomEvent("popup", {
            bubbles: true,
            cancelable: true,
            composed: true,
            detail: {
              feedKey: this.feedKey,
              id: contentId,
            },
          }))
        }
        this.carousel?.addEventListener('click', this._handleItemClick)
      } else {
        // TODO: Show message to user that no news items were found
      }
    } catch (err: unknown) {
      if (err instanceof ResponseError) {
        const status = err.response.status;
        switch(status) {
          case 400:
          case 404:
            // TODO: Handle specific error cases
            console.warn(`Error: ${err.message} [${err.response.status}]`);
            break;
          default:
            console.warn('Error: failed to fetch carousel items.');
          }
      } else if (err instanceof Error) {
        console.warn(`Error: ${err.message}`);
      } else {
        console.warn('Error: Unknown error occurred');
      }
    }
  }

  /**
   * Updates the carousel based on data attribute values.
   */
  public updateCarouselConfig() {
    const carousel: any = this.shadowRoot?.querySelector('#carousel');
    const small = this.getAttribute('data-item-small');
    const medium = this.getAttribute('data-item-medium');
    const large = this.getAttribute('data-item-large');
    const loop = this.getAttribute('data-loop');
    const loopT = loop == 'true' ? true : false;

    jQuery.noConflict();
    
    (function ($) {
      // Destory any previously existing carousel
      (<any>$(carousel)).trigger('destroy.owl.carousel');
      // Create new carousel with config below
      (<any>$(carousel)).owlCarousel({
        loop: loopT,
        margin: 16,
        nav: true,
        dots: false,
        navigationText: [
          '<span class="fa-stack"><i class="fa fa-circle fa-stack-1x"></i><i class="fa fa-chevron-circle-left fa-stack-1x fa-inverse"></i></span>',
          '<span class="fa-stack"><i class="fa fa-circle fa-stack-1x"></i><i class="fa fa-chevron-circle-right fa-stack-1x fa-inverse"></i></span>',
        ],
        responsive: {
          0: {
            items: small,
          },
          600: {
            items: medium,
          },
          1000: {
            items: large,
          },
        },
      });
    })(jQuery);
  }
}

customElements.define('carousel-component', CarouselComponent);
