const template = document.createElement('template');
const style = `
<style>
:host {
  font-family: "Roboto", sans-serif;
}
.grafa-advert {
  align-items: center;
  background: linear-gradient(0deg,#70efde 0,rgba(112,239,222,0) 100%),#49c8ef;
  border-radius: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: space-between;
  margin-block: 3rem 2rem;
  padding: 0.5rem 1.5rem;
  @container (max-width: 550px) {
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
}
.grafa-advert-title {
  color: #00406b;
  font-size: 2.125rem;
  font-weight: 600;
  line-height: 0.9;
  text-transform: uppercase;
  @container (max-width: 760px) {
    font-size: 1.5rem;
  }
  @container (max-width: 550px) {
    font-size: 1.25rem;
  }
}
.signup-button {
  align-items: center;
  background: #002d4a;
  border-radius: 9999px;
  color: #fff;
  display: inline-block;
  display: flex;
  font-size: 1.5rem;
  font-weight: 700;
  gap: 0.5rem;
  height: 2.5rem;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  max-width: 16.25rem;
  padding-inline: 1rem 0.5rem;
  text-decoration: none;
  & i {
    align-items: center;
    background: #53d1eb;
    border-radius: 9999px;
    display: flex;
    height: 1.5rem;
    justify-content: center;
    width: 1.5rem;
		& > img {
      margin-left: 0.25rem;
    }
    @container (max-width: 760px) {
      font-size: 1.5rem;
    }
    @container (max-width: 550px) {
      font-size: 1.25rem;
    }
  }
  @container (max-width: 760px) {
    font-size: 1.125rem;
  }
  @container (max-width: 550px) {
    font-size: 1rem;
  }
}
</style>
`;
const component = `
<div class="grafa-advert">
	<div class="grafa-advert-title">
		<span>Discover more</span>
		<br />
		<strong>with Grafa</strong>
	</div>
	<div class="grafa-advert-action">
		<a class="signup-button" href="https://www.grafa.com/">
			<span>Sign up now</span>
			<i>
				<img alt="play icon" src="https://images.grafa.com/bolton/play-solid.svg" />
			</i>
		</a>
	</div>
</div>
`;
template.innerHTML = `
${style}
${component}
`;

export class GrafaAdvertFooter extends HTMLElement {
	constructor() {
		super();
		const shadowRoot = this.attachShadow({ mode: 'open' })
		const clone = template.content.cloneNode(true)
		shadowRoot.append(clone)
	}
}

customElements.define('grafa-advert-footer', GrafaAdvertFooter)
