const template = document.createElement('template')
const style = `
<style>
	:host {
		font-family: "Roboto", sans-serif;
		white-space: normal;
	}
	article {
		container-type: inline-size;
		display: grid;
		gap: 0.5rem;
	}
	article:hover > h4 {
		text-decoration: underline;
	}
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
	h4 {
		display: -webkit-box;
		font-size: 1.5rem;
    font-weight: 600;
    color: #001f33;
		line-height: 1.25;
    margin-bottom: 0.625rem;
		margin-block: 0;
		overflow: hidden;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		@container (max-width: 475px) {
			font-size: 1.25rem;
		}
		@container (max-width: 350px) {
			font-size: 1.125rem;
		}
		@container (max-width: 200px) {
			font-size: 1rem;
		}
	}
	#source {
		font-size: 1.25rem;
    color: #26a8ff;
		@container (max-width: 475px) {
			font-size: 1rem;
		}
		@container (max-width: 350px) {
			font-size: 0.875rem;
		}
		@container (max-width: 200px) {
			font-size: 0.75rem;
		}
	}
	#date-time {
		font-size: 1rem;
    color: #002d4a;
		@container (max-width: 475px) {
			font-size: 0.85rem;
		}
		@container (max-width: 350px) {
			font-size: 0.75rem;
		}
	}
	.image-wrapper {
		aspect-ratio: 16 / 9;
		border-radius: 8px;
		height: auto;
		overflow: hidden;
		width: 100%;
	}
	.source-datetime-container {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
</style>
`
const component = `
<article>
	<div class="image-wrapper">
		<img alt="Article image/thumbnail" src="" />
	</div>
	<div class="source-datetime-container">
		<div id="source"></div>
		<div id="date-time"></div>
	</div>
	<h4></h4>
</article>
`
template.innerHTML = `
${style}
${component}
`

import { parseDate } from '../lib/datetime'

export class GridItem extends HTMLElement {

	constructor() {
		super();
		const shadowRoot = this.attachShadow({ mode: 'open' });
		const clone = template.content.cloneNode(true);
		shadowRoot.append(clone);
	}

	public connectedCallback() {
		this.render();
	}
	
	public render() {
		if (!this.shadowRoot)
			return
		const published = this.dataset.publish;
		const source = this.dataset.source;
		const thumbnail = this.dataset.thumbnail;
		const title = this.dataset.title;

		// Update image source
		const img = this.shadowRoot.querySelector('img');
		if (img) {
			img.setAttribute('src', thumbnail || '');
		}
		// Update publisher source
		const sourceEl = this.shadowRoot.querySelector('#source') as HTMLElement;
		if (sourceEl) {
			sourceEl.innerText = source || '';
		}
		// Update published date/time
		const publishedEl = this.shadowRoot.querySelector('#date-time') as HTMLElement;
		if (publishedEl) {
			publishedEl.innerText = parseDate(published || '') || '';
		}
		// Update title
		const h4 = this.shadowRoot.querySelector('h4');
		if (h4) {
			h4.innerText = title || '';
		}
	}
}

customElements.define('grid-item', GridItem)
