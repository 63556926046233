import type { MediaPlayer } from '@vime/core/dist/types'

const template = document.createElement('template');
const style = `
<style>
:host {
  font-family: "Roboto", sans-serif;
  white-space: normal;
}
#media-container > img {
  border-radius: 8px;
  height: auto;
  max-height: 24.375rem;
  object-fit: cover;
  width: 100%;
}
</style>
`
const component = `
<div id="media-container"></div>
`
template.innerHTML = `
${style}
${component}
`;

export class MediaComponent extends HTMLElement {
  public paused = false;
  public videoPlayer: MediaPlayer | null | undefined;
  public videoPlayerAttached = false;
  public videoPlayerLoaded = false;

  constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: 'open' });
    this.attachPlayer();
    const clone = template.content.cloneNode(true);
    shadowRoot.append(clone);
  }

  static get observedAttributes() {
    return ['media-type', 'media-url', 'thumbnail'];
  }

  get mediaType() {
    return this.getAttribute('media-type') || '';
  }

  public async connectedCallback() {
    this.render();
  }

  public disconnectedCallback() {
    const player = this.shadowRoot?.querySelector('vm-player') as any;
    if (player) {
      player.parentNode.removeChild(player);
    }
  }

  public render() {
    const container = this.shadowRoot?.querySelector('#media-container');
    if (container) {
      if (this.mediaType === 'Video') {
        this.attachPlayer();
        this.loadPlayer();
      } else if (this.mediaType === 'Image') {
        this.loadImage();
      }
    }
  }

  public attributeChangedCallback(attrName: string, _: any, newVal: any) {
    this._handleMediaType(attrName, newVal);
  }

  private _handleMediaType(attrName: string, value: string) {
    if (attrName !== 'media-type' || typeof value !== 'string' || !this.shadowRoot)
      return
    if (value === 'Video') {
      this.attachPlayer();
      this.loadPlayer();
    } else if (value === 'Image') {
      this.loadImage();
    }
  }

  public attachPlayer() {
    if (this.videoPlayerAttached)
      return

    const baseStyle = document.createElement('link');
    baseStyle.setAttribute('rel', 'stylesheet');
    baseStyle.setAttribute('href', 'https://cdn.jsdelivr.net/npm/@vime/core@^5/themes/default.css');
    this.shadowRoot?.appendChild(baseStyle);

    const basescript = document.createElement('script');
    basescript.setAttribute('type', 'module');
    basescript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/@vime/core@^5/dist/vime/vime.esm.js');
    this.shadowRoot?.appendChild(basescript);

    this.videoPlayerAttached = true;
  }

  public loadPlayer() {
    // Only load the player if it doesn't already exist. This line is required to handle a sporadic error
    // "Could not find player for VM-FILE" which isn't handled correctly.
    if (this.shadowRoot?.querySelector('vm-player'))
      return

    const thumbnail = this.getAttribute('thumbnail') || '';
    const mediaUrl = this.getAttribute('media-url') || '';
    const container = this.shadowRoot?.querySelector('#media-container');
    if (container) {
      // Add video player
      container.innerHTML = `
      <vm-player theme="light" controls autopause autoplay="true" muted="true" playsinline style="width: 100%">
      </vm-player>
      `;
      this.shadowRoot?.append(container);

      // If both mediaUrl & thumbnail exist, add Hls provider
      if (mediaUrl && thumbnail) {
        this.videoPlayer = this.shadowRoot?.querySelector('vm-player') as any;

        const vmHls = `
          <vm-hls version="latest" crossOrigin="" poster="${thumbnail}">
            <source data-src="${mediaUrl}" type="application/x-mpegURL"/>
          </vm-hls>
        `;
        if (this.videoPlayer) {
          this.videoPlayer.innerHTML = vmHls;
          this.videoPlayerLoaded = true;

          this.videoPlayer.addEventListener('pause', () => {
            this.paused = true;
          });
          
          this.videoPlayer.addEventListener('play', () => {
            this.paused = false;
          });
        }

        // Listen for video errors
        const hls = this.shadowRoot?.querySelector('vm-hls') as any;
        hls.addEventListener('vmError', (ev: CustomEvent) => {
          const err = ev?.detail?.data?.err;
          if (err)
            console.warn(err);
        })
      }
    }
  }

  public loadImage() {
    const mediaUrl = this.getAttribute('media-url');
    const container = this.shadowRoot?.querySelector('#media-container');
    if (container) {
      container.innerHTML = `<img src="${mediaUrl}" alt="banner" />`;
    }
  }

  public pause() {
    if (!this.videoPlayerLoaded || !this.videoPlayer || this.paused)
      return

    // Pause video
    this.videoPlayer.pause();
  }

  public play() {
    if (!this.videoPlayerLoaded || !this.videoPlayer || !this.paused)
      return

    // Pause video
    this.videoPlayer.play();
  }
}

customElements.define('media-component', MediaComponent);
