import type { ContentItem, MediaItem } from '../types/content'
import type { MediaComponent } from '../types/components'
import type { ContentApiService, CustomContentItemParams } from '../api/content';
import { stripTagsPreserveSpecialChars } from '../lib/text'
import { parseDate } from '../lib/datetime';
import { BasicContentService } from '../api/content';
import { ResponseError } from '../api/ResponseError';

const template = document.createElement('template');
const style = `
<style>
:host {
  container-type: inline-size;
  display: block;
  font-family: "Roboto", sans-serif;
  height: 100%;
  white-space: normal;
}
#grafa-footer {
  padding-inline: 2rem;
}
#grid-stack {
  margin-inline: 2rem;
  @container (max-width: 760px) {
    margin-inline: 1.5rem;
  }
  @container (max-width: 550px) {
    margin-inline: 1rem;
  }
}
#list-stack {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
}
.feature-source,
.feature-time {
  color: #f0f0f0;
  display:block;
  font-size: 1.25rem;
  font-weight:400;
  margin-bottom: 0;
}
.feature-title {
  font-size: 3rem;
  line-height: 1.1;
  margin: 0.5rem 0 1rem;
  @container (max-width: 1199px) {
    font-size: 2rem;
  }
}
.feature-body {
  color: #001f33;
  display: -webkit-box;
  font-size: 1.25rem;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  & p {
    margin: 0 0 1rem;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  @container (max-width: 1199px) {
    font-size: 1.125rem;
  }
}
.feature-view-more {
  color: #001f33;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  line-height: 1.5;
  display: inline-block;
  padding-block: 0.625rem;
}
</style>
`;
const component = `
<popup-wrapper withPageScroll="true">
  <div slot="wrapper-content">
    <grafa-header>
      <div slot="content">
        <span class="feature-source"></span>
        <h2 class="feature-title"></h2>
        <div class="feature-time"></div>
        <p class="feature-body"></p>
        <a href="javascript:void(0)" class="feature-view-more">
          View more
          <img src="https://images.grafa.com/bolton/arrow-right.svg" alt="arrow-right">
        </a>
      </div>
      <div slot="media">
        <media-component id="media"></media-component>
      </div>
    </grafa-header>
  </div>
</popup-wrapper>

<grid-filter>
  <div slot="before">
    <img alt="Powered by Grafa" src="https://images.grafa.com/bolton/logo.svg" />
  </div>
</grid-filter>

<popup-wrapper withPageScroll="true">
  <div slot="wrapper-content">
    <div id="grid-stack">
      <grid-component id="grid-view-component"></grid-component>
    </div>
    
    <div id="list-stack">
      <list-component id="list-view-component" style="display:none;"></list-component>
    </div>
  </div>
</popup-wrapper>

<div id="grafa-footer">
  <grafa-advert-footer></grafa-advert-footer>
</div>
`;
template.innerHTML = `
${style}
${component}
`;

export class BlogComponent extends HTMLElement {
  private headerAnchor: HTMLElement | null | undefined = null;
  private gridButton: HTMLElement | null | undefined = null;
  private listButton: HTMLElement | null | undefined = null;
  private sortSelect: HTMLElement | null | undefined = null;
  private gridStack: HTMLElement | null | undefined = null;
  private listStack: HTMLElement | null | undefined = null;
  public showType: string = 'grid';
  public contentService: ContentApiService;
  
  private _handleGridButtonClick = () => {};
  private _handleListButtonClick = () => {};
  private _handleSortSelectClick = () => {};
  private _handleSortChange = (_: Event) => {};
  private _handleStackItemClick = () => {};

  public shadow: any;

  constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: 'open' });
    let clone = template.content.cloneNode(true);
		shadowRoot.append(clone);

    this.contentService = new BasicContentService();
  }

  static get observedAttributes() {
    return ['data-feed-key', 'data-id', 'grid'];
  }

  get contentId() {
    const id = this.getAttribute('data-id') ?? '';
    return `${id}`
  }
  get feedKey() {
    return this.getAttribute('data-feed-key') ?? '';
  }

  public async connectedCallback() {
    await this.loadPageHeader();
    // Add grid/list toggle functionality
    this._onGridFilterEvent();
    // Add stack item event listeners
    this._onStackItemPopupEvent();
  }

  public disconnectedCallback() {
    this._removeGridFilterEventListeners();
    this._removeHeaderViewMoreEventListeners();
    this._removeStackItemEventListeners();
  }

  async attributeChangedCallback(attrName: string, _: string | null) {
    this._feedKeyChange(attrName);
  }

  /**
   * Updates the feed key responsible for uniquely identifying feed of content items to fetch.
   *
   * @param attrName Name of the changed attribute.
   */
  private _feedKeyChange(attrName: string) {
    if (attrName !== 'data-feed-key')
      return
    this.updateFeedKeyInChildComponents();
  }

  /**
   * Fetches an article by ContentId.
   * 
   * @param contentId 
   * @param feedKey 
   */
  public fetchArticleById(contentId: string, feedKey: string) {
    if (!contentId)
      return

    const itemParams: CustomContentItemParams = {
      contentId,
      feedKey,
    };
    return this.contentService.getCustomContentItem(itemParams)
  }

  /**
   * Fetches the header article, displays it and attaches any added functionality.
   */
  public async loadPageHeader() {
    try {
      const contentItem: ContentItem = await this.fetchArticleById(this.contentId, this.feedKey);
      if (contentItem) {
        const { Author, Body, ContentId, MediaType, MediaURL, PublishDate, ThumbnailURL, Title } = contentItem;
        this._setTextFromData('.feature-source', Author);
        this._setTextFromData('.feature-title', stripTagsPreserveSpecialChars(Title));
        this._setTextFromData('.feature-body', Body);
        this._setTextFromData('.feature-time', parseDate(PublishDate) || '');
        this._onHeaderViewMoreEvent(`${ContentId}`);
        this.loadMedia({ MediaType, MediaURL, ThumbnailURL });
      } else {
        // TODO: Show message to user that content item wasn't found
      }
    } catch (err: unknown) {
      if (err instanceof ResponseError) {
        const status = err.response.status;
        switch(status) {
          case 400:
          case 404:
            // TODO: Handle specific error cases
            console.warn(`Error: ${err.message} [${err.response.status}]`);
            break;
          default:
            console.warn('Error: failed to fetch news items.');
          }
      } else if (err instanceof Error) {
        console.warn(`Error: ${err.message}`);
      } else {
        console.warn('Error: Unknown error occurred');
      }
    }
  }

  /**
   * Remove header view more anchor click events.
   */
  private _removeHeaderViewMoreEventListeners() {
    this.headerAnchor?.removeEventListener('popup', this.dispatchPopupEvent);
  }

  /**
   * Adds a popup listener event to the view-more text in the page header.
   * 
   * @param contentId id of the article to open in the popup.
   */
  private _onHeaderViewMoreEvent(contentId: string) {
    if (!contentId)
      return
    this._removeHeaderViewMoreEventListeners();
    this.headerAnchor = this.shadowRoot?.querySelector('.feature-view-more');
    this.headerAnchor?.setAttribute('data-feed-key', this.feedKey);
    this.headerAnchor?.setAttribute('data-id', contentId);
    this.headerAnchor?.addEventListener('click', this.dispatchPopupEvent);
  }

  /**
   * Dispatches a popup event.
   * 
   * @param event 
   */
  public dispatchPopupEvent(event: Event) {
    const element = event.target as HTMLElement;
    const contentId = element?.dataset.id;
    const feedKey = element?.dataset.feedKey;
    // Emit popup event with contentId
    element?.dispatchEvent(new CustomEvent("popup", {
      bubbles: true,
      cancelable: false,
      composed: true,
      detail: {
        feedKey,
        id: contentId,
      },
    }));
  }

  /**
   * Helper to set the innerHTML of an element.
   * 
   * @param selector 
   * @param data 
   */
  private _setTextFromData(selector: string, data: string) {
    const selection: HTMLElement | null | undefined = this.shadowRoot?.querySelector(selector);
    if (selection)
      selection.innerHTML = data;
  }

  /**
   * Updates media section with the data required to show the image/video. 
   */
  public loadMedia({ MediaType, MediaURL, ThumbnailURL }: MediaItem) {
    const media = this.shadowRoot?.querySelector('#media');
    if (!media)
      return
    media.setAttribute('media-url', MediaURL);
    if (ThumbnailURL) {
      media.setAttribute('thumbnail', ThumbnailURL);
    } else {
      media.removeAttribute('thumbnail');
    }
    media.setAttribute('media-type', MediaType);
  }

  /**
   * Remove grid filter button click events.
   */
  private _removeGridFilterEventListeners() {
    if (this.gridButton) {
      this.gridButton.removeEventListener('click', this._handleGridButtonClick);
    }
    if (this.listButton) {
      this.listButton.removeEventListener('click', this._handleListButtonClick);
    }
    if (this.sortSelect) {
      this.sortSelect.removeEventListener('click', this._handleSortSelectClick);
      this.sortSelect.removeEventListener('change', this._handleSortChange);
    }
  }

  /**
   * Handle toggling between showing news content via grid or list.
   */
  private async _onGridFilterEvent() {
    // Remove any previously set up grid filter listeners
    this._removeGridFilterEventListeners();

    const gridFilter = this.shadowRoot?.querySelector('grid-filter');
    // Wait for grid-filter to render
    await customElements.whenDefined('grid-filter')
    this.gridButton = gridFilter?.shadowRoot?.querySelector('#grid-view');
    this.listButton = gridFilter?.shadowRoot?.querySelector('#list-view');
    this.sortSelect = gridFilter?.shadowRoot?.querySelector("#sorting");

    if (this.gridButton && this.listButton) {
      // Using arrow function to maintain reference to this.
      this._handleGridButtonClick = () => {
        this.showType = 'grid';
        this.shadowRoot?.querySelector('list-component')?.setAttribute('style', 'display:none;');
        this.shadowRoot?.querySelector('grid-component')?.setAttribute('style', 'display:block;');
        this.gridButton?.classList.add('active');
        this.listButton?.classList.remove('active'); 
      }
      this._handleListButtonClick = () => {
        this.showType = 'list';
        this.shadowRoot?.querySelector('list-component')?.setAttribute('style', 'display:block;');
        this.shadowRoot?.querySelector('grid-component')?.setAttribute('style', 'display:none;');
        this.listButton?.classList.add('active');
        this.gridButton?.classList.remove('active');
      }
      this.gridButton.addEventListener('click', this._handleGridButtonClick);
      this.listButton.addEventListener('click', this._handleListButtonClick);
    }

    if (this.sortSelect) {
      this._handleSortSelectClick = () => {
        this.showType = 'grid';
        this.shadowRoot?.querySelector('list-component')?.setAttribute('style', 'display:none;');
        this.shadowRoot?.querySelector('grid-component')?.setAttribute('style', 'display:block;');
        this.gridButton?.classList.add('active');
        this.listButton?.classList.remove('active'); 
      };
      this.sortSelect.addEventListener("click", this._handleSortSelectClick);
      this.sortSelect.addEventListener('change', this._handleSortChange);
    }
  }

  /**
   * Pauses the video player in the header section if it is currently playing.
   */
  public pauseHeaderVideo() {
    const headerMedia = this.shadowRoot?.querySelector('#media') as unknown;
    if (headerMedia) {
      (headerMedia as MediaComponent).pause();
    }
  }

  /**
   * Pauses the video player in the header section when a news article is clicked on via the 
   * grid or list stack.
   */
  private _onStackItemPopupEvent() {
    this.gridStack = this.shadowRoot?.getElementById('grid-view-component');
    this.listStack = this.shadowRoot?.getElementById('list-view-component');
    if (!this.gridStack || !this.listStack)
      return

    // Remove any previously setup listeners
    this._removeStackItemEventListeners();
    // Use arrow function to give 'this' context to pauseHeaderVideo
    this._handleStackItemClick = () => this.pauseHeaderVideo();

    this.gridStack.addEventListener('popup', this._handleStackItemClick);
    this.listStack.addEventListener('popup', this._handleStackItemClick);
  }

  /**
   * Remove grid/list stack item anchor click events.
   */
  private _removeStackItemEventListeners() {
    this.gridStack?.removeEventListener('popup', this._handleStackItemClick);
    this.listStack?.removeEventListener('popup', this._handleStackItemClick); 
  }

  public updateFeedKeyInChildComponents() {
    this.gridStack = this.shadowRoot?.getElementById('grid-view-component');
    if (this.gridStack) {
      this.gridStack.setAttribute('data-feed-key', this.feedKey);
    }
    this.listStack = this.shadowRoot?.getElementById('list-view-component');
    if (this.listStack) {
      this.listStack.setAttribute('data-feed-key', this.feedKey);
    }
  }
}

customElements.define('blog-component', BlogComponent);
