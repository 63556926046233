import { isValid } from 'date-fns'

const DEFAULT_DATETIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
	dateStyle: 'medium',
	timeStyle: 'short',
};

/**
 * Formats a date to display.
 * 
 * @param date date string or object
 * @param locales date locales to use
 * @param options Intl.datetime options
 * @returns a formatted date string or undefined
 */
export function parseDate(
	date: string | Date,
	locales: string | string[] = 'en-AU',
	options: Intl.DateTimeFormatOptions = DEFAULT_DATETIME_FORMAT_OPTIONS,
): string | undefined {
	// Convert date string to Date object
	if (typeof date === 'string') {
		// Ensure the timezone is GMT
		if (!date.endsWith('+00:00'))
			date = `${date}+00:00`;
		
		try {
			date = new Date(date);
		} catch (err) {
			console.warn('Error parsing date', err);
			return
		}
	}
	// Return undefined if date isn't valid
	if (!isValid(date))
		return
	return Intl.DateTimeFormat(locales, options).format(date);
}
