/**
 * Removes tags from string while preserving special characters.
 * 
 * @param input input string
 * @returns 
 */
export function stripTagsPreserveSpecialChars(input: any) {
	const parser = new DOMParser();
	const doc = parser.parseFromString(input, "text/html");
	return doc.body.textContent || "";
}
