import type { ContentItem } from '../types/content';
import type { ContentApiService, CustomContentFeedParams } from '../api/content';
import { ResponseError } from '../api/ResponseError';
import { unwrapElement, wrapElement } from '../lib/dom';
import { BasicContentService } from '../api/content';

const template = document.createElement('template');
const style = `
<style>
:host {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  max-width: 1400px;
  margin-inline: auto;
  min-height: 15rem;
  white-space: normal;
  width: 100%;
}
section {
  min-height: 1px;
  width: 100%;
}
#loading {
  display: flex;
  flex: 1;
}
#items {
  container-type: inline-size;
}
#pagination-actions {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  flex: 0;
  justify-content: center;
  margin-top: 2.5rem;
  & button {
    align-items: center;
    background: transparent;
    border: 0;
    color: #00000a;
    cursor: pointer;
    display: flex;
    font-size: 0.875rem;
    font-weight: 700;
    gap: 0.25rem;
    padding: 0 0.5rem;
    &:disabled {
      opacity: 0.5;
    }
  }
}
.default-loading {
  display: grid;
  margin: auto;
}
.grid-items {
  --grid-gap: 2.5rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: var(--grid-gap);
  & a {
    text-decoration: none;
  }
  @container (max-width: 990px) {
    --grid-gap: 2.25rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @container (max-width: 760px) {
    --grid-gap: 2rem;
  }
  @container (max-width: 540px) {
    --grid-gap: 1.5rem;
  }
}
.hide {
  display: none !important;
}
</style>
`;
const component = `
<div id="loading">
  <slot name="loading">
    <div class="default-loading">Loading...</div>
  </slot>
</div>
<section>
  <div id="items"></div>
  <div id="pagination-actions">
    <button id="prev-btn" disabled>
      <img src="https://images.grafa.com/bolton/chevron-left.svg" alt="chevron left">
      <span>Prev</span>
    </button>
    <button id="next-btn">
      <span>Next</span> 
      <img src="https://images.grafa.com/bolton/chevron-right.svg" alt="chevron right">
    </button>
  </div>
</section>
`;
template.innerHTML = `
${style}
${component}
`;

export class GridComponents extends HTMLElement {
  private gridItemContainer: HTMLElement | null | undefined = null;
  private items: HTMLElement | null | undefined = null;
  private nextButton: HTMLElement | null | undefined = null;
  private prevButton: HTMLElement | null | undefined = null;
  private elResizeObserver: ResizeObserver | undefined;
  public contentItems: ContentItem[] = [];
  public displayItems: ContentItem[] = [];

  public contentService: ContentApiService;

  private currentPage: number = 1;
  private previousDate: string[] = [];
  private gridSize = 0;

  private _handleItemClick = (_: MouseEvent) => {};
  private _handleNextButtonClick = () => {};
  private _handlePrevButtonClick = () => {};
  private _onResize = (_: ResizeObserverEntry[]) => {};

  constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: 'open' });
    const clone = template.content.cloneNode(true);
    shadowRoot.append(clone);

    this.contentService = new BasicContentService();
  }

  static get observedAttributes() {
    return ['data-feed-key', 'standalone'];
  }

  get feedKey() {
    return this.getAttribute('data-feed-key') ?? '';
  }

  public async connectedCallback() {
    this.showLoader();
    // Add grid/list toggle functionality
    this._onPagingEvent();
  }

  public disconnectedCallback() {
    this.elResizeObserver?.disconnect();
    this._removeGridItemEventListener();
    this._removePagingEventListeners();
  }

  async attributeChangedCallback(attrName: string, _: string | null, newValue: string) {
    this._attachOrRemovePopupWrapper(attrName, newValue);
    this._feedKeyChange(attrName);
  }

  /**
   * Attaches/removes article popup functionality based on the standalone prop.
   *
   * @param attrName Name of the changed attribute.
   * @param value New value of changed attribute.
   */
  private _attachOrRemovePopupWrapper(attrName: string, value: string) {
    if (attrName !== 'standalone')
      return
    const itemsWrapper = this.shadowRoot?.getElementById('items');
    if (itemsWrapper) {
      const popupWrapper = document.createElement('popup-wrapper');
      if (value === 'true') {
        // Attach popup wrapper
        itemsWrapper.setAttribute('slot', 'wrapper-content');
        wrapElement(itemsWrapper, popupWrapper);
      } else {
        const popupWrapper = this.shadowRoot?.querySelector('popup-wrapper') as HTMLElement;
        if (!popupWrapper)
          return
        // Otherwise remove popup wrapper if it exists
        const parent = this.shadowRoot || undefined;
        unwrapElement(itemsWrapper, popupWrapper, parent);
      }
    }
  }

  /**
   * Updates the feed key responsible for uniquely identifying feed of content items to fetch.
   *
   * @param attrName Name of the changed attribute.
   */
  private _feedKeyChange(attrName: string) {
    if (attrName !== 'data-feed-key')
      return
    this.renderGrid();
  }

  /**
   * Fetches the content items to display in the grid/list.
   *
   * @param publishDate 
   * @returns 
   */
  public async fetchData(feedParams: CustomContentFeedParams) {
    this.showLoader();
    return this.contentService.getCustomContentFeed(feedParams)
      .finally(() => this.hideLoader());
  }

  /**
   * Display the loading indicator.
   */
  showLoader() {
    const loading = this.shadowRoot?.querySelector('#loading');
    loading?.classList.toggle('hide', false);
  }

  /**
   * Hide the loading indicator.
   */
  hideLoader() {
    const loading = this.shadowRoot?.querySelector('#loading');
    loading?.classList.toggle('hide', true);
  }

  /**
   * Renders the grid with the fetched items.
   *
   * @param fromDate 
   * @returns 
   */
  private async renderGrid(fromDate?: string) {
    this.items = this.shadowRoot?.querySelector('#items');
    if (!this.items)
      return

    // Update last fetched published date to use when fetching next page
    const publishDate = fromDate ? fromDate : this.displayItems?.at(-1)?.PublishDate || '';
    try {
      const contentItems = await this.fetchData({ feedKey: this.feedKey, publishDate });
      if (contentItems.length) {
        if (this.currentPage > 1) {
          this.prevButton?.removeAttribute('disabled');
        } else {
          this.prevButton?.setAttribute('disabled', 'disabled');
        }
        this.contentItems = contentItems;
        this.displayItems = this.items.offsetWidth > 990 ? this.contentItems.slice(0,9) : this.contentItems;
      } else {
        // TODO: Show message to user that no news items were found
      }
    } catch (err: unknown) {
      if (err instanceof ResponseError) {
        const status = err.response.status;
        switch(status) {
          case 400:
          case 404:
            // TODO: Handle specific error cases
            console.warn(`Error: ${err.message} [${err.response.status}]`);
            break;
          default:
            console.warn('Error: failed to fetch news items.');
          }
      } else if (err instanceof Error) {
        console.warn(`Error: ${err.message}`);
      } else {
        console.warn('Error: Unknown error occurred');
      }
    }

    this._setupResizeObserver()
    this._renderGridItems();
  }

  private _renderGridItems() {
    const displayItems = this.displayItems?.map((item: ContentItem) => {
      return `
        <a href="javascript:void(0)">
          <grid-item
            data-id="${item.ContentId}"
            data-title="${item.Title}"
            data-thumbnail="${item.ThumbnailURL}"
            data-source="${item.Source}"
            data-publish="${item.PublishDate}">
          </grid-item>
        </a>
      `
    });
    const grid = `
      <div class="grid-items">
        ${displayItems?.join("")}
      </div>
    `;

    if (this.items)
      this.items.innerHTML = grid;

    this._removeGridItemEventListener()
    this.gridItemContainer = this.shadowRoot?.querySelector('.grid-items')
    this._handleItemClick = (event: MouseEvent) => {
      const anchor = (event.target as HTMLElement)?.closest('a')
      const contentId = (anchor?.querySelector('grid-item') as HTMLElement)?.dataset.id;
      // Emit popup event with contentId
      anchor?.dispatchEvent(new CustomEvent("popup", {
        bubbles: true,
        cancelable: false,
        composed: true,
        detail: {
          feedKey: this.feedKey,
          id: contentId,
        },
      }))
    }
    this.gridItemContainer?.addEventListener('click', this._handleItemClick)
  }

  private _setupResizeObserver() {
    if (!this.elResizeObserver) {
      this._onResize = (entries: ResizeObserverEntry[]) => {
        // Fixes Error - ResizeObserver loop completed with undelivered notifications
        // https://stackoverflow.com/questions/76187282/react-resizeobserver-loop-completed-with-undelivered-notifications
        window.requestAnimationFrame((): void | undefined => {
          if (!Array.isArray(entries) || !entries.length || !this.contentItems?.length) {
            return;
          }
          const component = entries[0]
          let newGridSize
          // Check to see whether or not the grid size has changed
          if (component.contentRect.width > 990) {
            newGridSize = 9
          } else {
            newGridSize = 10
            this.displayItems = this.contentItems
          }
          // If grid size has changed, update the number of displayed items in the grid
          // 9 for 3x3, 10 for 2x5
          if (this.gridSize !== newGridSize) {
            this.displayItems = this.contentItems.slice(0, newGridSize);
            this.gridSize = newGridSize;
            this._renderGridItems();
          }
        });
      }
      const section = this.shadowRoot?.querySelector('section')
      if (section) {
        this.elResizeObserver = new ResizeObserver(this._onResize)
        this.elResizeObserver.observe(section)
      }
    }
  }

  /**
   * Remove grid item click event listener.
   */
  private _removeGridItemEventListener() {
    this.gridItemContainer?.removeEventListener('click', this._handleItemClick)
  }

  /**
   * Remove grid filter button click events.
   */
  private _removePagingEventListeners() {
    if (this.nextButton) {
      this.nextButton.removeEventListener('click', this._handleNextButtonClick);
    }
    if (this.prevButton) {
      this.prevButton.removeEventListener('click', this._handlePrevButtonClick);
    }
  }

  /**
   * Handle paging via previous/next buttons.
   */
  private async _onPagingEvent() {
    // Remove any previously set up grid filter listeners
    this._removePagingEventListeners();

    this.nextButton = this.shadowRoot?.querySelector('#next-btn');
    this.prevButton = this.shadowRoot?.querySelector('#prev-btn');

    if (this.nextButton && this.prevButton) {
      // // Using arrow function to maintain reference to this.
      this._handleNextButtonClick = async () => {
        if (!this.items)
          return
        this.items.innerHTML = '';
        
        if (this.contentItems.length) {
          const date = this.contentItems.at(0)?.PublishDate ?? '';
          if (date)
            this.previousDate.push(date)
        }
        this.currentPage++;
        await this.renderGrid();
      }
      this._handlePrevButtonClick = async () => {
        if (!this.items)
          return
        this.items.innerHTML = '';
        
        this.currentPage--;
        await this.renderGrid(this.previousDate.pop());
      }
      this.nextButton.addEventListener('click', this._handleNextButtonClick);
      this.prevButton.addEventListener('click', this._handlePrevButtonClick);
    }
  }
}

customElements.define('grid-component', GridComponents);
