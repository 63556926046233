import { ResponseError } from '../api/ResponseError';

export function fetchResponse(input: RequestInfo | URL, init?: RequestInit | undefined) {
  return fetch(input, init).then(
    async (response: Response) => {
      if (response.ok) {
        try {
          const data = await response.json();
          return data;
        } catch (err: unknown) {
          // Failed to parse json
          if (err instanceof SyntaxError) {
            console.error(err);
          } else {
            console.error(err);
          }
        }
      } else {
        throw new ResponseError(response);
      }
    },
  );
}
