import { fetchResponse } from "./fetch";
const CONTENT_API = 'https://content.grafa.com';
const CUSTOM_CONTENT_API = `${CONTENT_API}/customcontentfeed`;
const DEFAULT_HEADERS = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export type ContentApiService = {
  getCustomContentFeed: (params: CustomContentFeedParams) => Promise<any>;
  getCustomContentItem: (params: CustomContentItemParams) => Promise<any>;
};
export type CustomContentFeedParams = {
  feedKey: string;
  limit?: number;
  publishDate?: string;
  sortDirection?: string;
  sortField?: string;
};
export type CustomContentItemParams = {
  contentId: string;
  feedKey: string;
};

export class BasicContentService implements ContentApiService {
  constructor() {}

  /**
   * Fetches a predefined content feed of news articles using a specific feed key.
   * Example url: https://content.grafa.com/customcontentfeed/list?FeedKey=my-grafa-key-3
	 *
   * @param params
   * @returns list of feed items on success.
   */
  getCustomContentFeed(params: CustomContentFeedParams): Promise<any> {
    const { feedKey, publishDate, sortDirection, sortField } = params;
    if (!feedKey) throw new Error('Missing feed key');

    const urlParams = new URLSearchParams({
      FeedKey: feedKey,
    });
    if (publishDate) urlParams.append('PublishDate', publishDate);
    if (sortField) urlParams.append('SortField', sortField);
    if (sortDirection) urlParams.append('SortDirection', sortDirection);

    return fetchResponse(`${CUSTOM_CONTENT_API}/list?${urlParams.toString()}`, DEFAULT_HEADERS)
  }

  /**
   * Fetches a predefined content news item from a specific feed using the items id.
   * Example url: https://content.grafa.com/customcontentfeed/id?ContentId=134734&FeedKey=my-grafa-key-3
	 *
   * @param params
   * @returns a specific feed item on success.
   */
  getCustomContentItem(params: CustomContentItemParams): Promise<any> {
    const { contentId, feedKey } = params;
    if (!feedKey || !contentId) throw new Error(`Missing ${!contentId ? 'contentId' : 'feed key'}`);

    const urlParams = new URLSearchParams({
      ContentId: contentId,
      FeedKey: feedKey,
    });

    return fetchResponse(`${CUSTOM_CONTENT_API}/id?${urlParams.toString()}`, DEFAULT_HEADERS);
  }
}
