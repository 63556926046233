export function unwrapElement(el: HTMLElement, wrapper: HTMLElement, shadowRoot?: ShadowRoot) {
	if ((!el && !wrapper))
		return
	const parent = wrapper.parentNode
	if (parent) {
		parent.insertBefore(el, wrapper);
		parent?.removeChild(wrapper);
	} else if (shadowRoot) {
		shadowRoot.appendChild(el)
	}
}

export function wrapElement(el: HTMLElement, wrapper: HTMLElement) {
	if ((!el && !wrapper) || !el.parentNode)
		return
	el.parentNode.insertBefore(wrapper, el);
	wrapper.appendChild(el);
	return wrapper
}
