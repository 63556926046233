const template = document.createElement('template')
// @import 'global.css';
const style = `
<style>
:host {
  font-family: "Roboto", sans-serif;
  white-space: normal;
}
header {
	background: linear-gradient(0deg, #70efde 0,rgba(112,239,222,0) 100%), #49c8ef;
  container-type: inline-size;
  margin-bottom: 1.5rem;
}
#wrapper {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-inline: auto;
  max-width: 87.5rem;
  padding: 5rem 1rem;
  @container (max-width: 900px) {
    grid-template-columns: 100%;
  }
}
#content {
  padding-inline: 1rem;
  @container (max-width: 760px) {
    padding-inline: 0;
  }
}
#media {
  display: grid;
  margin-block: auto;
}
</style>
`
const component = `
<header>
  <div id="wrapper">
    <div id="content">
      <slot name="content"></slot>
    </div>
    <div id="media">
      <slot name="media"></slot>
    </div>
  </div>
</header>
`
template.innerHTML = `
${style}
${component}
`

export class PageHeaderComponent extends HTMLElement {
	constructor() {
		super();
		const shadowRoot = this.attachShadow({ mode: 'open' });
		let clone = template.content.cloneNode(true);
		shadowRoot.append(clone);
	}

	static get observedAttributes() {
    return ['bgColor'];
  }

  get bgColor() {
    return this.getAttribute('bgColor') || ''
  }

	public attributeChangedCallback(attrName: string, _: any, newVal: any) {
    this._handleBgColor(attrName, newVal);
  }

	/**
   * Changes the background-color of the banner.
   * 
   * @param attrName 
   * @param value 
   * @returns 
   */
  private _handleBgColor(attrName: string, value: string) {
    if (attrName !== 'bgColor' || typeof value !== 'string' || !this.shadowRoot)
      return
    const bannerEl = this.shadowRoot.querySelector<HTMLElement>('header');
    if (!bannerEl)
      return
    bannerEl.style.backgroundColor = value;
  }
}

customElements.define('grafa-header', PageHeaderComponent)
