const template = document.createElement('template');
const style = `
<style>
:host {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 1.5rem 1rem;
	white-space: normal;
}

button {
	align-items: center;
	border: 1px solid #dadada;
	border-radius: 2px;
	display: flex;
	height: 2rem;
	justify-content: center;
	margin-left: 0.25rem;
	width: 2rem;
}
button.active {
	background: rgb(111 237 223 / 15%);
	border-color: #49c8ef;
}
button img {
	height: 1rem;
}
#sorting {
	border: 1px solid #dadada;
	border-radius: 2px;
	height: 2rem;
	margin-left: 0.25rem;
	padding-inline: 0.5rem 0.25rem;
}
.filter-wrapper {
	display: flex;
}
</style>
`
const component = `
	<div>
		<slot name="before"></slot>
	</div>
	<div class="filter-wrapper">
		<button type="button" class="gridView active" id="grid-view">
			<img  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAKlBMVEUAAAD///+QkJAgICDX19eCgoKMjIzq6uq9vb19fX0NDQ3Z2dmJiYkQEBBCur8EAAABs0lEQVR4nO3cO1IDQRBEQbHoC+j+18VdzIpBlBplHqCin7fj7OHtvzu0D3g4hfMpnE/hfArnUzifwvkUzqdwPoXzKZxP4XwK5ztsq86X/d71vDp33c9dVue27bDuuD/ptjx3288df+G+dT8KT8tzJ4V/T2FIYYHCkMIChSGFBQpDCgsUhhQWKAwpLFAYUligMKSwQGFIYYHCkMIChSGFBQpDCgsUhhQWKAwpLFAYUligMKSwQGFIYYHCkMIChSGFBQpDCgsUhhQWKAwpLFAYUligMKSwQGFIYex91cfn/qSv++Lc/Ws/9/mxfN8L/IGnfcDDKZxP4XwK51M4n8L5XqBw+dv96d8W6579fbhOYUhhgcKQwgKFIYUFCkMKCxSGFBYoDCksUBhSWKAwpLBAYUhhgcKQwgKFIYUFCkMKCxSGFBYoDCksUBhSWKAwpLBAYUhhgcKQwgKFIYUFCkMKCxSGFBYoDCksUBhSWKAwpLBAYUhhwYsV3pbnbr9duK06X/YnXc+rc9f93GV1btte4A887QMeTuF8CudTOJ/C+RTOp3A+hfMpnE/hfArnUzifwvm+ARFFW9hdiXEFAAAAAElFTkSuQmCC" />
		</button>
		<button type="button" class="listView " id="list-view">
			<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAYFBMVEX///8AAAC3t7e8vLzExMTm5ub8/PwcHBwZGRnW1tZ1dXXe3t709PQUFBTq6ur39/eQkJA6Ojpqamo8PDxQUFBZWVkjIyMICAibm5umpqZWVlbR0dGurq7IyMjc3Nynp6eEPrg5AAAC0UlEQVR4nO3Za2+iQBiG4akolYMong/t7v//l4vprqkMavLmmU6Yva+PJM28d8EB1DkAAODL6/qSTcYou9R1/ipvNlkv3sZssZ7MnvRNN7EHlNhMH/QVu9ijyewK/8Pn8jL2WFKl94FcbmPPJLbtJRb72BPJ7e+u1OYQe54ADs23SzSNPbRvs7wVprOJ3tv920aPsScJ5vj3OSb2HAF9Pd+kdSO8V6Z+Cr9O4ir2EEGtusI29hBBtc5VsWcIrHKn2CMEdkp6J70q3Tn2CIGdE99orlvNPPYIgc1d7AmCo3D8KBw/CsePwvGjcPwoHD+/cPU51fv88Nb5CLKO/72aX5g9+ZHYrvHWaV7/kUEWrdD/VvbZz+12FFJoR6EKhRTaUahCIYV2FKpQSKEdhSoUUmhHoQqFFNpRqEIhhXYUqlBIoR2FKhRSaEehCoUU2lGoQiGFdhSqUEihHYUqFFJoR6EKhRTaUahCIYV2FKpQSKEdhSoUUmhHoQqFFNpRqEIhhXYUqlBIoR2FKhRSaEehCoUU2lGoQiGFdhSqUEihHYUqFFJoR6EKhRTaUahCIYV2FKpQSKEdhSoUUmhHoQqFFNpRqEIhhXYUqlBIoR2FKhRSaEehCoUU2lGoQiGFdhSqUEihHYUqFFJoR6HKf1k47x85Bln5pwpP/WUWru0f2gZZ+UcKc+e2/WVad/aWDnISG2+ZJsQyR2+Zsyu9Y29ltczFXOWt8itXr1JXQzHut3+wu3jf1Rb+GnOt97m/Ruc08M9NS+X8rSYpbffhXMUeIqiVG9rHU1Jd7yEDG1Ayyge3qnTMHt0mU3F7eNnFniSQ3e1ZZ7mJPUsQ7fLbU+Mh9jQBHO6efIt97Hnk9sX9Q3nuvXaM3Dbvv3fkad0Wy6F3qyKdLXVXDAV2Z3Waxp66mT55R559rAffskZjsZ68/Hokr+tLNhmj7NLU3v4CAAA6fwBubWsrHfGAPwAAAABJRU5ErkJggg==" />                        
		</button>
	</div>
	`
// <select name="sorting" id="sorting" class="graph-ds-form-control">
// 	<option value="">Select- Sort by</option>                          
// 	<option value="DESC">Latest</option>
// 	<option value="ASC">Oldest</option>
// </select>
template.innerHTML = `
${style}
${component}
`

export class GridFilterComponent extends HTMLElement {
	constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: 'open' });
		let clone = template.content.cloneNode(true);
		shadowRoot.append(clone);
	}

	public async connectedCallback() {}
}

customElements.define('grid-filter', GridFilterComponent);
