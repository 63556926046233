import type { ContentItem, MediaItem } from '../types/content'
import type { ContentApiService, CustomContentItemParams } from '../api/content';
import { parseDate } from '../lib/datetime';
import { stripTagsPreserveSpecialChars } from '../lib/text';
import { BasicContentService } from '../api/content';
import { ResponseError } from '../api/ResponseError';

const template = document.createElement('template')
const style = `
<style>
:host {
  font-family: "Roboto", sans-serif;
  white-space: normal;
}
article {
  container-type: inline-size;
  margin-inline: auto;
  max-width: 900px;
  width: 100%;
}
h1 {
  color: #002d4a;
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.1;
  margin-block: 0 1.5rem;
}
#author-picture {
  border-radius: 9999px;
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
}
#author-name {
  font-size: 0.875rem;
  font-weight: 700;
  color: #49c8ef;
  margin-bottom: 0.125rem;
}
#publish-date {
  font-size: 0.875rem;
  font-weight: 400;
  color: #000;
}
#share {
  align-items: center;
  background: #002d4a;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
}
.author-actions {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.article-wrapper {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  position: relative;
  padding: 2.5rem 1rem 0;
  transform: translateY(-100%);
  transition: 0.3s;
  width: 92vw;
  &::before {
    background: linear-gradient(0deg,#70efde 0,rgba(112,239,222,0) 100%),#49c8ef;
    content: "";
    height: 20rem;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  & > * {
    z-index: 2;
  }
}
.article-dialogue {
  background: rgba(0,0,0,0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  overflow: auto;
  overscroll-behavior-y: contain;
  scroll-behavior: smooth;
  transition: background 0.3s;
  z-index: 9999;
}
.article-dialogue.article-dialogue-opened {
  background: rgba(0,0,0,0.5);
}
.article-dialogue.article-dialogue-opened .article-wrapper {
  transform: translate(0);
}
.article-dialogue::-webkit-scrollbar{
  width: 0;
}
.article-dialogue::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
}
.article-dialogue::-webkit-scrollbar-thumb{
  background-color: transparent;
  outline: 0;
}
.author-details {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}
.back {
  align-items: center;
  display: flex;
  font-size: 1rem;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
}
.back img {
  margin-right: 0.5rem;
  transition: 0.3s ease-in-out;
}
.back:hover img{
  margin-right: 0.25rem;
}
.body {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  margin-block: 2.5rem 3.5rem;
  & > p {
    margin: 0 0 1.25rem;
  }
}
.header-actions {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  & a {
    font-size: 1.25rem;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
  }
}
.tagline {
  color: #002d4a;
  font-size: 1.25rem;
  margin-block: 2rem 0.5rem;
}
</style>
`
const component = `
<div class="article-dialogue">
  <div class="article-wrapper">
    <article>
      <div class="header-actions">
        <a href="javascript:void(0)" class="back">
          <img src="https://images.grafa.com/bolton/arrow-left.svg" alt="arrow left"> 
          <span>Back</span>
        </a>
        <a href="https://www.grafa.com/">
          <img src="https://images.grafa.com/bolton/logo-white.svg" alt="logo" />
        </a>
      </div>
      <media-component id="media"></media-component>
      <div class="tagline"></div>
      <h1></h1>
      <div class="author-actions">
        <div class="author-details">
          <img id="author-picture" alt="Author picture" src="" />
          <div>
            <div id="author-name"></div>
            <div id="publish-date"></div>
          </div>
        </div>
        <!-- <a id="share" href="javascript:void(0)">
          <img alt="Share" src="https://images.grafa.com/bolton/upload.svg" />
        </a> -->
      </div>
      <div class="body"></div>
      <carousel-component data-item-small="2" data-item-medium="3" data-item-large="4" data-loop="false"></carousel-component>
      <grafa-advert-footer></grafa-advert-footer>
    </article>
  </div>
</div>
`
template.innerHTML = `
${style}
${component}
`

export class PopupComponent extends HTMLElement {
  private carousel: HTMLElement | null = null;

  public contentService: ContentApiService;

  private _handlePopup = (_: any) => {};

  constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: 'open' });
    const clone = template.content.cloneNode(true);
    shadowRoot.append(clone);

    this.contentService = new BasicContentService();
  }

  static get observedAttributes() {
    return ['data-feed-key', 'data-id', 'withPageScroll'];
  }

  get contentId() {
    const id = this.getAttribute('data-id') ?? '';
    return `${id}`
  }
  get feedKey() {
    return this.getAttribute('data-feed-key') ?? '';
  }

  public async connectedCallback() {
    setTimeout(() => {
      const rerender = this.getAttribute('rerender');
      if (rerender === 'true') {
        this.removeAttribute('rerender');
      } else {
        this.shadowRoot?.querySelector('.article-dialogue')?.classList.toggle('article-dialogue-opened', true);
      }
    });

    // Add back button events
    this.shadowRoot?.querySelector('.back')?.addEventListener('click', () => {
      this.shadowRoot?.querySelector('.article-dialogue')?.classList.toggle('article-dialogue-opened', false);
      setTimeout(() => {
        this.dispatchEvent(new CustomEvent("back", {
          bubbles: true,
          cancelable: false,
          composed: true,
        }));
      }, 300);
    });

    const withPageScroll = this.getAttribute('withPageScroll')
    const scrollTop = withPageScroll === 'true' ? document.documentElement.scrollTop : undefined
    setTimeout(() => {
      const dialogue = this.shadowRoot?.querySelector('.article-dialogue') as HTMLElement;
      if (dialogue && withPageScroll === 'true') {
        dialogue.style.top = `${scrollTop}px`
      }
    });

  }

  public disconnectedCallback() {
    this.carousel?.addEventListener('popup', this._handlePopup);
  }

  public attributeChangedCallback(attrName: string, oldVal: any, newVal: any) {
    this._feedKeyChange(attrName, newVal);
    this._contentItemChanged(attrName, newVal, oldVal);
  }

  /**
   * Scrolls to the top of the page and loads the article when content id changes.
   *
   * @param attrName Name of the changed attribute.
   * @param value Changed value.
   * @param oldVal Old value.
   */
  private _contentItemChanged(attrName: string, value: string, oldVal: string) {
    if (attrName !== 'data-id' || typeof value !== 'string' || !this.shadowRoot)
      return
    // Scroll to the top of the dialogue
    const dialogue = this.shadowRoot?.querySelector('.article-dialogue');
    if (dialogue) {
      dialogue.scrollTop = 0;
    };
    if (oldVal)
      this.setAttribute('rerender', 'true');
    // Update content item
    this.loadArticle();
  }

  /**
   * Updates the feed key attribute of child components.
   *
   * @param attrName Name of the changed attribute.
   * @param value Changed value.
   */
  private _feedKeyChange(attrName: string, value: string) {
    this.carousel = this.shadowRoot?.querySelector('carousel-component') as HTMLElement;
    if (attrName !=='data-feed-key' || !this.carousel)
      return
    this.carousel.dataset.feedKey = value;
  }

  /**
   * Fetches an article by ContentId.
   * 
   * @param contentId 
   * @param feedKey 
   */
  public fetchArticleById(contentId: string, feedKey: string) {
    if (!contentId || !feedKey)
      return

    const itemParams: CustomContentItemParams = {
      contentId,
      feedKey,
    };
    return this.contentService.getCustomContentItem(itemParams)
  }

  public async loadArticle() {
    try {
      const contentItem: ContentItem = await this.fetchArticleById(this.contentId, this.feedKey);
      if (contentItem) {
        const { Author, AuthorThumbnail, Body, Category, ContentId, MediaType, MediaURL, PublishDate, ThumbnailURL, Title } = contentItem;
        this.loadMedia({ MediaType, MediaURL, ThumbnailURL })

        this._setTextFromData('.tagline', Category)
        this._setTextFromData('#author-name', Author ? `Written by ${Author}` : '')
        this._setTextFromData('#publish-date', parseDate(PublishDate) || '')
        this._setTextFromData('h1', stripTagsPreserveSpecialChars(Title))

        const authorPicture = this.shadowRoot?.querySelector('#author-picture') as HTMLElement
        if (authorPicture) {
          authorPicture.setAttribute('src', AuthorThumbnail)
        }
        const body = this.shadowRoot?.querySelector('.body') as HTMLElement
        if (body) {
          body.innerHTML = Body
        }

        this.carousel = this.shadowRoot?.querySelector('carousel-component') as HTMLElement
        this.carousel?.setAttribute('viewing', `${ContentId}`)
        // Remove any previous popup event listeners
        this.carousel?.removeEventListener('popup', this._handlePopup);
        this._handlePopup = (ev: CustomEvent) => {
          if (!ev?.detail?.id || !ev?.detail?.feedKey)
            return
      
          this.dataset.feedKey = ev.detail.feedKey;
          this.dataset.id = ev.detail.id;
          ev.stopPropagation();
        }
        this.carousel?.addEventListener('popup', this._handlePopup);
      } else {
        // TODO: Show message to user that content item wasn't found
      }
    } catch (err: unknown) {
      if (err instanceof ResponseError) {
        const status = err.response.status;
        switch(status) {
          case 400:
          case 404:
            // TODO: Handle specific error cases
            console.warn(`Error: ${err.message} [${err.response.status}]`);
            break;
          default:
            console.warn('Error: failed to fetch news items.');
          }
      } else if (err instanceof Error) {
        console.warn(`Error: ${err.message}`);
      } else {
        console.warn('Error: Unknown error occurred');
      }
    }
  }

  /**
   * Updates media section with the data required to show the image/video. 
   */
  public loadMedia({ MediaType, MediaURL, ThumbnailURL }: MediaItem) {
    const media = this.shadowRoot?.querySelector('#media')
    if (!media)
      return
    media.setAttribute('media-url', MediaURL)
    if (ThumbnailURL) {
      media.setAttribute('thumbnail', ThumbnailURL)
    } else {
      media.removeAttribute('thumbnail')
    }
    media.setAttribute('media-type', MediaType)
  }

  private _setTextFromData(selector: string, data: string) {
    const selection: HTMLElement | null | undefined = this.shadowRoot?.querySelector(selector)
    if (selection)
      selection.innerText = data
  }
}

customElements.define('popup-component', PopupComponent);
