const template = document.createElement('template');
const style = `
<style>
:host {
  font-family: "Roboto", sans-serif;
  white-space: normal;
}
</style>
`;
const component = `
<blog-component BgColor="" data-feed-key="my-grafa-key-3" data-id="120257"></blog-component>
`;
template.innerHTML = `
${style}
${component}
`;

export class MainComponent extends HTMLElement {
  constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: 'open' });
    const clone = template.content.cloneNode(true);
    shadowRoot.append(clone);
  }
}

customElements.define('main-components', MainComponent);
