const template = document.createElement('template');
const style = `
<style>
:host {
  font-family: "Roboto", sans-serif;
}
#card {
  background: linear-gradient(0deg,#70efde 0,rgba(112,239,222,0) 100%),#49c8ef;
  border-radius: 1rem;
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 760px;
  padding: 1rem 1.5rem;
  width 100%;
}
#action {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
#description {
  line-height: 1.4;
  max-width: 60ch;
  @container (max-width: 550px) {
    font-size: 0.875rem;
  }
  @container (max-width: 350px) {
    font-size: 0.85rem;
  }
}
#title {
  color: #002D4A;
  font-size: 2.125rem;
  font-weight: 400;
  line-height: 0.9;
  text-transform: uppercase;
  & :first-child {
    letter-spacing: -0.125rem;
  }
  & :last-child {
    letter-spacing: 0px;
    font-weight: 900;
  }
  @container (max-width: 550px) {
    font-size: 1.5rem;
  }
  @container (max-width: 350px) {
    font-size: 1.25rem;
  }
}
.signup-button {
  align-items: center;
  background: #002d4a;
  border-radius: 8px;
  color: #fff;
  display: inline-block;
  display: flex;
  font-size: 1.25rem;
  font-weight: 500;
  gap: 2.5rem;
  height: 2.5rem;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  max-width: 20.25rem;
  padding-inline: 1rem 0.5rem;
  text-decoration: none;
  text-transform: uppercase;
  & i {
    align-items: center;
    background: #53d1eb;
    border-radius: 9999px;
    display: flex;
    height: 1.5rem;
    justify-content: center;
    width: 1.5rem;
    & > img {
      margin-left: 0.25rem;
    }
    @container (max-width: 550px) {
      font-size: 1.5rem;
      height: 1.25rem;
      width: 1.25rem;
      & > img {
        height: 0.75rem;
      }  
    }
  }
  @container (max-width: 550px) {
    font-size: 1.125rem;
    height: 2rem;
  }
  @container (max-width: 350px) {
    font-size: 1;
    height: 1.75rem;
  }
}
</style>
`;
const component = `
<div id="card">
	<div id="title">
		<span>Personalise your</span>
		<br />
		<span>news with Grafa</span>
	</div>
  <div id="description">
    Curate this feed to only show news about the assets in your portfolio with a Grafa Gold subscription.
  </div>
	<div id="action">
		<a class="signup-button" href="https://www.grafa.com/">
			<span>Sign up now</span>
			<i>
				<img alt="play icon" src="https://images.grafa.com/bolton/play-solid.svg" />
			</i>
		</a>
	</div>
</div>
`;
template.innerHTML = `
${style}
${component}
`;

export class GrafaAdvert extends HTMLElement {
	constructor() {
		super();
		const shadowRoot = this.attachShadow({ mode: 'open' })
		const clone = template.content.cloneNode(true)
		shadowRoot.append(clone)
	}
}

customElements.define('grafa-advert', GrafaAdvert)
