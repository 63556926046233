import type { ContentItem } from '../types/content';
import type { ContentApiService, CustomContentFeedParams } from '../api/content';
import { ResponseError } from '../api/ResponseError';
import { unwrapElement, wrapElement } from '../lib/dom';
import { BasicContentService } from '../api/content';

const template = document.createElement('template');
const style = `
<style>
:host {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  margin-inline: auto;
  white-space: normal;
  width: 100%;
}
footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding-block: 0.5rem;
  & > button {
    align-items: center;
    background: unset;
    border: unset;
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-right: 1rem;
    & :last-child {
      font-size: 1.25rem;
      line-height: 1;
      padding-bottom: 0.25rem;
    }
  }
}
section {
  width: 100%;
}
#loading {
  display: flex;
  flex: 1;
}
#items {
  container-type: inline-size;
}
#pagination-actions {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 2.5rem;
  width: 100%;
  & button {
    align-items: center;
    background: transparent;
    border: 0;
    color: #00000a;
    cursor: pointer;
    display: flex;
    font-size: 0.875rem;
    font-weight: 700;
    gap: 0.25rem;
    padding: 0 0.5rem;
    &:disabled {
      opacity: 0.5;
    }
  }
}
.default-loading {
  display: grid;
  margin: auto;
}
.list-items {
  --list-gap: 2rem;
  display: grid;
  grid-template-columns: 100%;
  gap: var(--list-gap);
  & a {
    text-decoration: none;
  }
  @container (max-width: 990px) {
    --list-gap: 1.5rem;
  }
  @container (max-width: 760px) {
    --list-gap: 1.25rem;
  }
}
.hide {
  display: none !important;
}
</style>
`;
const component = `
<div id="loading">
  <slot name="loading">
    <div class="default-loading">Loading...</div>
  </slot>
</div>
<section>
  <div id="items"></div>
  <footer>
    <div id="pagination-actions">
      <button id="prev-btn" disabled>
        <img src="https://images.grafa.com/bolton/chevron-left.svg" alt="chevron left">
        <span>Prev</span>
      </button>
      <button id="next-btn">
        <span>Next</span> 
        <img src="https://images.grafa.com/bolton/chevron-right.svg" alt="chevron right">
      </button>
    </div>
  </footer>
</section>
`;
template.innerHTML = `
${style}
${component}
`;

export class ListComponent extends HTMLElement {
  private listItemContainer: HTMLElement | null | undefined = null;
  private items: HTMLElement | null | undefined = null;
  private nextButton: HTMLElement | null | undefined = null;
  private prevButton: HTMLElement | null | undefined = null;
  private viewMoreEl: HTMLElement | null | undefined;
  public contentItems: ContentItem[] = [];

  public contentService: ContentApiService;

  private currentPage: number = 1;
  private previousDate: string[] = [];

  private _handleItemClick = (_: MouseEvent) => {};
  private _handleNextButtonClick = () => {};
  private _handlePrevButtonClick = () => {};

  constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: 'open' });
    const clone = template.content.cloneNode(true);
    shadowRoot.append(clone);

    this.contentService = new BasicContentService();
  }

  static get observedAttributes() {
    return ['data-feed-key', 'standalone', 'view-more'];
  }

  get feedKey() {
    return this.getAttribute('data-feed-key') ?? '';
  }

  get viewMore() {
    return this.getAttribute('view-more');
  }

  public async connectedCallback() {
    this.showLoader();
    // Add grid/list toggle functionality
    this._onPagingEvent();
  }

  public disconnectedCallback() {
    this._removeListItemEventListener();
    this._removePagingEventListeners();
    this._removeViewMoreEventListeners();
  }

  async attributeChangedCallback(attrName: string, _: string | null, newValue: string) {
    this._attachOrRemovePopupWrapper(attrName, newValue);
    this._setViewMore(attrName, newValue);
    this._feedKeyChange(attrName);
  }

  /**
   * Adds or removes the component that handles article popup.
   *
   * @param attrName Name of the changed attribute.
   * @param value Changed value.
   */
  private _attachOrRemovePopupWrapper(attrName: string, value: string) {
    if (attrName !== 'standalone')
      return
    const itemsWrapper = this.shadowRoot?.getElementById('items');
    if (itemsWrapper) {
      const popupWrapper = document.createElement('popup-wrapper');
      if (value === 'true') {
        // Attach popup wrapper
        itemsWrapper.setAttribute('slot', 'wrapper-content')
          wrapElement(itemsWrapper, popupWrapper)
      } else {
        const popupWrapper = this.shadowRoot?.querySelector('popup-wrapper') as HTMLElement;
        if (!popupWrapper)
          return
        // Otherwise remove popup wrapper if it exists
        const parent = this.shadowRoot || undefined
        unwrapElement(itemsWrapper, popupWrapper, parent)
      }
    }
  }

  /**
   * Updates the feed key responsible for uniquely identifying feed of content items to fetch.
   *
   * @param attrName Name of the changed attribute.
   */
  private _feedKeyChange(attrName: string) {
    if (attrName !== 'data-feed-key')
      return
    this.renderList();
  }

  /**
   * Removes the viewMore event listener from view more button if it exists. 
   */
  private _removeViewMoreEventListeners() {
    this.viewMoreEl = this.shadowRoot?.getElementById('view-more');
    this.viewMoreEl?.removeEventListener('click', this.dispatchViewMoreEvent);
  }

  /**
   * Show/hide the view more anchor/button and attaches any events.
   *
   * @param attrName attribute name to check against.
   * @param value the value of the attribute.
   */
  private _setViewMore(attrName: string, value: string | undefined) {
    if (attrName !== 'view-more')
      return
    const footer = this.shadowRoot?.querySelector('footer')
    if (!footer)
      return

    // Reset view more
    this.viewMoreEl = this.shadowRoot?.getElementById('view-more');
    this._removeViewMoreEventListeners();
     // Reset paging
     this._removePagingEventListeners();
     const pagination = this.shadowRoot?.getElementById('pagination-actions');
     
     // Remove the element if it exists and the viewMore attribute is false
     // Show the pagination actions by default
     if (!value || value === 'false') {
       this.viewMoreEl?.parentNode?.removeChild(this.viewMoreEl);
       
       // Remove pagination-actions if it exists
      pagination?.parentNode?.removeChild(pagination);

      // Create new pagination element
      const newPagination = document.createElement('div');
      newPagination.setAttribute('id', 'pagination-actions');
      newPagination.innerHTML = `
        <button id="prev-btn" disabled>
          <img src="https://images.grafa.com/bolton/chevron-left.svg" alt="chevron left">
          <span>Prev</span>
        </button>
        <button id="next-btn">
          <span>Next</span> 
          <img src="https://images.grafa.com/bolton/chevron-right.svg" alt="chevron right">
        </button>
      `;
      // Append pagination to footer
      footer?.appendChild(newPagination);
      // Add grid/list toggle functionality
      this._onPagingEvent();
      return
    }

    // If 'true' then dispatch a view more event for external listening
    if (value === 'true') {
      // Reset footer
      footer.innerHTML = ''
      const TAGLINE_TEXT = 'Powered by Grafa';
      const buttonInnerHTML = `
      <span>View more</span>
      <span>›</span>
      `;

      // Create and append tagline
      const taglineEl = document.createElement('div');
      taglineEl.classList.add('tag-line');
      taglineEl.innerText = TAGLINE_TEXT
      footer?.appendChild(taglineEl);

      // Create and append view more
      const viewMoreEl = document.createElement('button');
      viewMoreEl.setAttribute('id', 'view-more');
      viewMoreEl.setAttribute('type', 'button');
      viewMoreEl.innerHTML = buttonInnerHTML
      footer?.appendChild(viewMoreEl);
      viewMoreEl.addEventListener('click', this.dispatchViewMoreEvent);
    }
  }

  /**
   * Dispatches a view more event for external listeners to action.
   * 
   * @param event
   */
  public dispatchViewMoreEvent(event: Event) {
    const element = event.target as HTMLElement;
    // Emit viewMore event
    element?.dispatchEvent(new CustomEvent("viewMore", {
      bubbles: true,
      cancelable: false,
      composed: true,
      detail: {
        feedKey: this.feedKey,
        component: 'list-component',
      },
    }));
  }

  /**
   * Fetches the content items to display in the grid/list.
   *
   * @param publishDate 
   * @returns 
   */
  private async fetchData(feedParams: CustomContentFeedParams) {
    this.showLoader();
    return this.contentService.getCustomContentFeed(feedParams)
      .finally(() => this.hideLoader());
  }

  /**
   * Display the loading indicator.
   */
  showLoader() {
    const loading = this.shadowRoot?.querySelector('#loading');
    loading?.classList.toggle('hide', false);
  }

  /**
   * Hide the loading indicator.
   */
  hideLoader() {
    const loading = this.shadowRoot?.querySelector('#loading');
    loading?.classList.toggle('hide', true);
  }

  /**
   * Renders the list with the fetched items.
   *
   * @param fromDate 
   * @returns 
   */
  private async renderList(fromDate?: string) {
    this.items = this.shadowRoot?.querySelector('#items');
    if (!this.items)
      return

    // Update last fetched published date to use when fetching next page
    const publishDate = fromDate ? fromDate : this.contentItems?.at(-1)?.PublishDate || '';
    try {
      const contentItems = await this.fetchData({ feedKey: this.feedKey, publishDate });
      if (contentItems.length) {
        if (this.currentPage > 1) {
          this.prevButton?.removeAttribute('disabled');
        } else {
          this.prevButton?.setAttribute('disabled', 'disabled');
        }
        this.contentItems = contentItems;
      } else {
        // TODO: Show message to user that no news items were found
      }
    } catch (err: unknown) {
      if (err instanceof ResponseError) {
        const status = err.response.status;
        switch(status) {
          case 400:
          case 404:
            // TODO: Handle specific error cases
            console.warn(`Error: ${err.message} [${err.response.status}]`);
            break;
          default:
            console.warn('Error: failed to fetch news items.');
          }
      } else if (err instanceof Error) {
        console.warn(`Error: ${err.message}`);
      } else {
        console.warn('Error: Unknown error occurred');
      }
    }

    const displayItems = this.contentItems?.map((item: ContentItem) => {
      return `
        <a href="javascript:void(0)">
          <list-item
            data-id="${item.ContentId}"
            data-title="${item.Title}"
            data-thumbnail="${item.ThumbnailURL}"
            data-source="${item.Source}"
            data-publish="${item.PublishDate}">
          </list-item>
        </a>
      `
    });
    const list = `
      <div class="list-items">
        ${displayItems?.join("")}
      </div>
    `;

    this.items.innerHTML = list;

    this._removeListItemEventListener()
    this.listItemContainer = this.shadowRoot?.querySelector('.list-items')
    this._handleItemClick = (event: MouseEvent) => {
      const anchor = (event.target as HTMLElement)?.closest('a')
      const contentId = (anchor?.querySelector('list-item') as HTMLElement)?.dataset.id;
      // Emit popup event with contentId
      anchor?.dispatchEvent(new CustomEvent("popup", {
        bubbles: true,
        cancelable: false,
        composed: true,
        detail: {
          feedKey: this.feedKey,
          id: contentId,
        },
      }))
    }
    this.listItemContainer?.addEventListener('click', this._handleItemClick)
  }

  /**
   * Remove list item click event listener.
   */
  private _removeListItemEventListener() {
    this.listItemContainer?.removeEventListener('click', this._handleItemClick)
  }

  /**
   * Remove list filter button click events.
   */
  private _removePagingEventListeners() {
    if (this.nextButton) {
      this.nextButton.removeEventListener('click', this._handleNextButtonClick);
    }
    if (this.prevButton) {
      this.prevButton.removeEventListener('click', this._handlePrevButtonClick);
    }
  }

  /**
   * Handle paging via previous/next buttons.
   */
  private async _onPagingEvent() {
    // Remove any previously set up list filter listeners
    this._removePagingEventListeners();

    this.nextButton = this.shadowRoot?.querySelector('#next-btn');
    this.prevButton = this.shadowRoot?.querySelector('#prev-btn');

    if (this.nextButton && this.prevButton) {
      // // Using arrow function to maintain reference to this.
      this._handleNextButtonClick = async () => {
        if (!this.items)
          return
        this.items.innerHTML = '';
        
        if (this.contentItems.length) {
          const date = this.contentItems.at(0)?.PublishDate ?? '';
          if (date)
            this.previousDate.push(date)
        }
        this.currentPage++;
        await this.renderList();
      }
      this._handlePrevButtonClick = async () => {
        if (!this.items)
          return
        this.items.innerHTML = '';
        
        this.currentPage--;
        await this.renderList(this.previousDate.pop());
      }
      this.nextButton.addEventListener('click', this._handleNextButtonClick);
      this.prevButton.addEventListener('click', this._handlePrevButtonClick);
    }
  }
}

customElements.define('list-component', ListComponent);
