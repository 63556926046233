const template = document.createElement('template');
const style = `
<style>
:host {
  font-family: "Roboto", sans-serif;
  white-space: normal;
}
</style>
`
const component = `
<slot name="wrapper-content"></slot>
`
template.innerHTML = `
${style}
${component}
`

export class PopupWrapperComponent extends HTMLElement {
  /**
   * Closes an opened popup.
   */
  private _handleBack: () => void = () => {};
  /**
   * Closes an opened popup on Escape key press.
   */
  private _handleEscapePopup: (ev: Event) => void = (_: any) => {};
  /**
   * Closes an opened popup on click outside dialogue.
   */
  private _handleClickOutsideModal: (ev: Event) => void = (_: any) => {};

	constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: 'open' });
    let clone = template.content.cloneNode(true);
		shadowRoot.append(clone);
  }

	static get observedAttributes() {
    return ['withPageScroll'];
  }

	public async connectedCallback() {
		this.addEventListener('popup', this._handlePopup);
		this._onBackEvent();
	}
	
	public disconnectedCallback() {
		this.removeEventListener('popup', this._handlePopup);
		this._removeBackEventListener();
		this._removeEscapeEventListener();
		this._removeCloseOnOutsideModalEventListener();
	}

	/**
   * Removes the back event listener for the article back button.
   */
  private _removeBackEventListener() {
    if (!this._handleBack)
      return
    const popupV = this.shadowRoot?.getElementById('popup-component');
    popupV?.removeEventListener('back', this._handleBack);
  }

  /**
   * Removes the escape key keydown event listener for the popup modal. 
   */
  private _removeEscapeEventListener() {
    if (!this._handleEscapePopup)
    return
    document.removeEventListener('keydown', this._handleEscapePopup);
  }

  /**
   * Removes the event listener for closing the popup modal on outside modal click.  
   */
  private _removeCloseOnOutsideModalEventListener() {
    if (!this._handleBack)
      return
    const popupV = this.shadowRoot?.querySelector('popup-component');
    if (!popupV)
      return
    const dialogue = popupV?.shadowRoot?.querySelector('.article-dialogue');
    if (!dialogue)
      return
    dialogue.removeEventListener('click', this._handleClickOutsideModal)
  }

	/**
   * Attaches the back event listener for the article back button.
   */
  private async _onBackEvent() {
    this._removeBackEventListener()

    this._handleBack = () => {
      const popupV = this.shadowRoot?.querySelector('popup-component') as Node;
      this._removeEscapeEventListener();
      this._removeCloseOnOutsideModalEventListener();
      this.shadowRoot?.removeChild(popupV)
    }
    
    // Add article back event
    await customElements.whenDefined('popup-component');
    const popupV = this.shadowRoot?.querySelector('popup-component');
    popupV?.addEventListener('back', this._handleBack);
  }

	/**
   * Opens the popup based on an article's ContentId.
   *
   * @param data 
   */
  private async _handlePopup(ev: Event) {
    const custom = ev as CustomEvent;
    if (!custom?.detail?.id || !custom?.detail?.feedKey)
      return

    const existingPopup = this.shadowRoot?.querySelector('popup-component');
    if (existingPopup) {
      this.shadowRoot?.removeChild(existingPopup);
    }
    const popup = document.createElement('popup-component');
		const withPageScroll = this.getAttribute('withPageScroll');
		if (withPageScroll === 'true') {
			popup.setAttribute('withPageScroll', withPageScroll);
		}
    popup.dataset.feedKey = custom.detail.feedKey;
    popup.dataset.id = custom.detail.id;
    this.shadowRoot?.appendChild(popup);

    this._onEscapeEvent();
    this._onClickOutsideModalEvent();
    this._onBackEvent();
  }

  /**
   * Adds the event listener for escape button presses to close an open dialogue.
   */
  private async _onEscapeEvent() {
    this._removeEscapeEventListener();
    this._handleEscapePopup = (ev: Event) => {
      const keyEv = ev as KeyboardEvent;
      // Check event for escape key button press
      if (!(keyEv.key === "Escape" || keyEv.key === "Esc"))
        return;

      if (!this._handleBack)
        return
      this._handleBack();
    }

    document.addEventListener('keydown', this._handleEscapePopup);
  }

  /**
   * Adds the event listener to the popup to handle clicking outside the dialogue.
   */
  private async _onClickOutsideModalEvent() {
    this._removeCloseOnOutsideModalEventListener();

    this._handleClickOutsideModal = (ev: Event) => {
      const mouseEv = ev as MouseEvent;
      // Check event to make sure target is outside dialogue
      let paths = mouseEv.composedPath();
      if (paths.some(target => {
        const el = target as HTMLElement;
        return el.className && el.className.includes('article-wrapper');
      }))
        return
      
      if (!this._handleBack)
        return
      this._handleBack();
    }

    await customElements.whenDefined('popup-component');
    const popupV = this.shadowRoot?.querySelector('popup-component');
    if (!popupV)
      return
    const dialogue = popupV?.shadowRoot?.querySelector('.article-dialogue');
    if (!dialogue)
      return
    dialogue.addEventListener('click', this._handleClickOutsideModal);
  }
}

customElements.define('popup-wrapper', PopupWrapperComponent);
